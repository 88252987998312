import axios from "../../axios/index.js"

export default {
  fetchCities(meta) {
    return axios.get("plans/city", {
      params: meta
    })
  },
  searchCities(meta) {
    return axios.get("plans/city/search", {
      params:  meta
    })
  },
  createCity(payload) {
    return axios.post("plans/city", payload)
  },
  updateCity(payload) {
    return axios.post(`plans/city/${payload.id}`, payload)
  },
}
