import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

import moduleAuth from './auth/moduleAuth.js'
import moduleCustomer from './customer/moduleCustomer.js'
import moduleAdmin from './admin/moduleAdmin.js'
import modulePlan from './plan/modulePlan.js'
import moduleImport from './import/moduleImport.js'
import moduleTransaction from './transaction/moduleTransaction.js'
import modulePlot from './plot/modulePlot.js'
import moduleCity from './city/moduleCity.js'

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        auth: moduleAuth,
        customer: moduleCustomer,
        admin: moduleAdmin,
        plan: modulePlan,
        import: moduleImport,
        transaction: moduleTransaction,
        plot: modulePlot,
        city: moduleCity
    },
    strict: process.env.NODE_ENV !== 'production'
})
