/* eslint-disable no-debugger, no-console */
import transaction from "../../http/requests/transaction/index.js"

export default {
  fetchTransactions({ commit }, payload) {
     return new Promise((resolve, reject) => {
       transaction.fetchTransactions(payload.meta)
         .then((response) => {
           if(response.data.data) {
             commit('SET_TRANSACTIONS', response.data.data)
             commit('SET_META', response.data.meta)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         }  else {
             reject({message: response.data.message})
         }

         })
         .catch((error) => {
            commit('SET_TRANSACTIONS', [])
            reject(error)
          })
     })
  },
  updateTransaction({ commit }, payload) {
   return new Promise((resolve, reject) => {
     transaction.updateTransaction(payload)
       .then((response) => {
         if(response.status === 200) {
           commit('SET_TRANSACTION', response.data.data)
           commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
           resolve(response)
       } else {
           reject({message: "Something went wrong!"})
       }

       })
       .catch((error) => {
          console.log(error)
          reject(error.response.data)
        })
   })
 },
 fetchTransaction({ commit }, id) {
   return new Promise((resolve, reject) => {
     transaction.fetchTransaction(id)
       .then((response) => {
          if(response.data.data) {
            commit('SET_TRANSACTION', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
        } else {
           reject({message: response.data.message})
        }
       })
       .catch((error) => {
         reject(error)
       })
   })
 },
  updatePaginate({ commit }, size) {
    commit('UPDATE_PAGINATE', size)
  }
}
