export default {
  SET_HISTORY(state, imports) {
    state.imports = imports
  },
  SET_META(state, meta) {
   state.meta.total = meta.total
   state.meta.to = meta.to
   state.meta.from = meta.from
   state.meta.last_page = meta.last_page
   state.meta.current_page = meta.current_page
   if(meta.current_page != meta.last_page) {
     state.meta.next_page = meta.current_page + 1
   }
  },
  UPDATE_PAGINATE(state, size) {
    state.meta.paginate = size
  },
}
