export default {
   listSubClassification(state) {
     return state.sub_classifications.map(sub => {
       return {
         value: sub.id,
         text: sub.name
       }
     })
   },
   listResidentialClassification(state) {
      return state.sub_classifications.filter(sub => {
         return sub.classification === 'Residential'
      })
   },
   listCommercialClassification(state) {
     return state.sub_classifications.filter(sub => {
       return sub.classification === 'Commercial'
     })
   }
}
