import axios from "../../axios/index.js"

export default {
  fetchTransactions(meta) {
    return axios.get("plans/transactions", {
      params: meta
    })
  },
  fetchTransaction(id) {
    return axios.get(`plans/transactions/${id}`)
  },
  updateTransaction(payload) {
    return axios.post(`plans/transactions/${payload.id}`, payload.transaction)
  }
}
