// axios
import axios from 'axios'

const domain = ""

// let baseURL = 'https://test-api.mokhatat.com/api/admin'
let baseURL = 'https://api-dev.mokhatat.com/api/admin'

//baseUrl: url can go in the instance later.

if (process.env.NODE_ENV === "production") {
  baseURL = "https://api-prod.mokhatat.com/api/admin"
}

//baseURL = "https://api-prod.mokhatat.com/api/admin"

export default axios.create({
  domain,
  baseURL: baseURL
  // You can add your headers here
})
