/* eslint-disable no-debugger, no-console */
import jwt from '../../http/requests/auth/jwt/index.js'

const getPasswordResetTokenErrors = (error) => {
  let msg = "Encounter an error. Please try again";
  if (error.response.data.message){
    msg = error.response.data.message
  }else if(error.response.data.error.message){
    msg = error.response.data.error.message
  }
  return msg;
};

export default {
  // JWT
  loginJWT ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.email, payload.userDetails.password)
        .then(response => {
          if (response.data.token) {
            localStorage.setItem('accessToken', response.data.token)

            //commit('SET_BEARER', response.data.token)

            Object.assign(response.data.admin, {remember_me: payload.checkbox_remember_me})
            commit('UPDATE_USER_INFO', response.data.admin, {root: true})
            location.replace('/')
            resolve(response)
          } else {
            reject({message: 'Wrong Email or Password'})
          }

        })
        .catch(error => {
           reject(error.response.data.message)
         })
    })
  },
  fetchAccessToken () {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => { resolve(response) })
    })
  },
  passwordResetToken(dispatch, payload) {
   return jwt.passwordResetToken(payload)
   .then(({data}) => {
     console.log(data)
     return data
   })
   .catch(error => {
    if(typeof getPasswordResetTokenErrors(error) === "string"){
      return getPasswordResetTokenErrors(error)
    }
       return "Encounter an error. Please try again"
   })
  },
  resetPassword(dispatch,payload) {
    return jwt.resetPassword(payload)
    .then(({data}) => {
     return data
    })
    .catch(error => {
      throw getPasswordResetTokenErrors(error)
    })
  }
}
