export default {
  plots: [],
  sub_classifications: [],
  feedbacks: [],
  shared: [],
  viewed: [],
  most_viewed: [],
  meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
  feedback_meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
  shared_meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
  viewed_meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
  most_viewed_meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
  sub_classification_meta: {
    current_page: 1,
    next_page: 1,
    paginate: 200,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
}
