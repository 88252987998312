import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import * as VueGoogleMaps from 'vue2-google-maps'
import JsonExcel from "vue-json-excel";

/*
import { extend } from 'vee-validate';
import { required, email, min, numeric } from 'vee-validate/dist/rules';
import { ValidationProvider } from 'vee-validate' */
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'

import Snotify, { SnotifyPosition } from 'vue-snotify'

import axios from './axios.js'


import store from './store/store'

import acl from './acl/acl'


Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)

const options = {
  toast: {
    position: SnotifyPosition.rightTop,
  }
}
Vue.use(Snotify, options)

Vue.prototype.$http = axios


const accessToken = localStorage.getItem('accessToken')
if (accessToken) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
}

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    libraries: 'places',
  },
})
/*
extend('email', email)
extend('required', required)
extend('min', min)
extend('numeric', numeric) */

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('downloadExcel', JsonExcel)

new Vue({
  router,
  store,
  acl,
  render: h => h(App),
}).$mount('#app')
