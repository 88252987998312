export default {
  SET_CUSTOMERS(state, customers) {
    state.customers = customers
  },
  SET_EXEL_CUSTOMERS(state, customers) {
    state.exelCustomers = customers
  },
  SET_CUSTOMER(state, customer) {
    if(state.customers.length) {
      const itemIndex = state.customers.findIndex((u) => u.id === customer.id)
      if(itemIndex != -1) {
        state.customers[itemIndex] = customer
      }
      else state.customers.push(customer)
    }
    else state.customers.push(customer)
  },
  UPDATE_CUSTOMER(state, customer) {
    if(state.customers.length) {
      const itemIndex = state.customers.findIndex((u) => u.id === customer.id)
      if(itemIndex != -1) {
        state.customers[itemIndex] = customer
      }
      else state.customers.push(customer)
    }
    else state.customers.push(customer)
  },
  SET_META(state, meta) {
   state.meta.total = meta.total
   state.meta.to = meta.to
   state.meta.from = meta.from
   state.meta.last_page = meta.last_page
   state.meta.current_page = meta.current_page
   if(meta.current_page != meta.last_page) {
     state.meta.next_page = meta.current_page + 1
   }
  },
  UPDATE_PAGINATE(state, size) {
    state.meta.paginate = size
  },
}
