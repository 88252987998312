export default {
  SET_PLANS(state, plans) {
    state.plans = plans
  },
  SET_STYLES(state, styles) {
    state.styles = styles
  },
  SET_PLAN_FAVOURITES(state, favourites) {
    state.favourite_plans = favourites
  },
  SET_PLOT_FAVOURITES(state, favourites) {
    state.favourite_plots = favourites
  },
  SET_UPLOAD_STATUSES(state, statuses) {
    state.upload_statuses = statuses
  },
  SET_SHARED(state, shared) {
    state.shared = shared
  },
  SET_META(state, meta) {
   state.meta.total = meta.total
   state.meta.to = meta.to
   state.meta.from = meta.from
   state.meta.last_page = meta.last_page
   state.meta.current_page = meta.current_page
   if(meta.current_page != meta.last_page) {
     state.meta.next_page = meta.current_page + 1
   }
  },
  SET_STATUS_META(state, meta) {
    state.status_meta.total = meta.total
    state.status_meta.to = meta.to
    state.status_meta.from = meta.from
    state.status_meta.last_page = meta.last_page
    state.status_meta.current_page = meta.current_page
    if(meta.current_page != meta.last_page) {
      state.status_meta.next_page = meta.current_page + 1
    }
   },
  SET_PLAN_FAVOURITES_META(state, meta) {
    state.favourite_plan_meta.total = meta.total
    state.favourite_plan_meta.to = meta.to
    state.favourite_plan_meta.from = meta.from
    state.favourite_plan_meta.last_page = meta.last_page
    state.favourite_plan_meta.current_page = meta.current_page
    if(meta.current_page != meta.last_page) {
      state.favourite_plan_meta.next_page = meta.current_page + 1
    }
   },
   SET_PLOT_FAVOURITES_META(state, meta) {
    state.favourite_plot_meta.total = meta.total
    state.favourite_plot_meta.to = meta.to
    state.favourite_plot_meta.from = meta.from
    state.favourite_plot_meta.last_page = meta.last_page
    state.favourite_plot_meta.current_page = meta.current_page
    if(meta.current_page != meta.last_page) {
      state.favourite_plot_meta.next_page = meta.current_page + 1
    }
   },
   SET_SHARED_META(state, meta) {
    state.shared_meta.total = meta.total
    state.shared_meta.to = meta.to
    state.shared_meta.from = meta.from
    state.shared_meta.last_page = meta.last_page
    state.shared_meta.current_page = meta.current_page
    if(meta.current_page != meta.last_page) {
      state.shared_meta.next_page = meta.current_page + 1
    }
   },
  SET_PLAN(state, plan) {
    if(state.plans.length) {
      const itemIndex = state.plans.findIndex((p) => p.id === plan.id)
      if(itemIndex != -1) {
        state.plans[itemIndex] = plan
      }
      else state.plans.push(plan)
    }
    else state.plans.push(plan)
  },
  SET_STYLE(state, style) {
    if(state.styles.length) {
      const itemIndex = state.styles.findIndex((s) => s.id === style.id)
      if(itemIndex != -1) {
        state.styles[itemIndex] = style
      }
      else state.styles.push(style)
    }
    else state.styles.push(style)
  },
  SET_UPLOAD_PROCESS(state, process) {
    state.upload_process = process
  },
  UPDATE_PAGINATE(state, size) {
    state.meta.paginate = size
  },
}
