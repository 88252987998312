/* eslint-disable no-debugger, no-console */

import customer from "../../http/requests/customer/index.js"

export default {
  fetchCustomers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      customer.fetchCustomers(payload.meta)
        .then((response) => {
           if(response.data.data[0]) {
             commit('SET_CUSTOMERS', response.data.data)
             commit('SET_META', response.data.meta)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchExelCustomers({ commit }) {
    return new Promise((resolve, reject) => {
      customer.fetchExelCustomers()
        .then((response) => {
          if(response.data.data[0]) {
            commit('SET_EXEL_CUSTOMERS', response.data.data)
            resolve(response)
          } else {
            reject({message: response.data.message})
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchActiveCustomers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      customer.fetchActiveCustomers(payload.meta)
        .then((response) => {
           if(response.data.data[0]) {
             commit('SET_CUSTOMERS', response.data.data)
             commit('SET_META', response.data.meta)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchCustomers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      customer.searchCustomers(payload.meta)
        .then((response) => {
           if(response.data.data[0]) {
             commit('SET_CUSTOMERS', response.data.data)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchCustomerList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      customer.searchCustomerList(payload.search,payload.start_date,payload.end_date, payload.paginate, payload.page)
        .then((response) => {
          console.log(2,response.data);
          commit('SET_CUSTOMERS', response.data.data)
          commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
          commit('SET_META', response.data.meta)
          resolve(response)
        //    if(response.data.data[0]) {
        //  } else {
        //     reject({message: response.data.message})
        //  }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCustomer({ commit }, id) {
    return new Promise((resolve, reject) => {
      customer.fetchCustomer(id)
        .then((response) => {
           if(response.data.data) {
             commit('SET_CUSTOMER', response.data.data)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteCustomer({ commit }, id) {
    return new Promise((resolve, reject) => {
      customer.deleteCustomer(id)
        .then((response) => {
           if(response.data.status == "success") {
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateCustomer({ commit }, payload) {
    return new Promise((resolve, reject) => {
      customer.updateCustomer(payload)
        .then((response) => {
           if(response.data.status == "success") {
             commit('UPDATE_CUSTOMER', response.data.data)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updatePaginate({ commit }, size) {
    commit('UPDATE_PAGINATE', size)
  }
}
