import city from "../../http/requests/city/index.js"

export default {
  createCity({ commit }, payload) {
   return new Promise((resolve, reject) => {
     city.createCity(payload)
       .then((response) => {
         if(response.status === 201) {
           commit('SET_CITY', response.data.data)
           commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
           resolve(response)
       } else {
           reject({message: "Something went wrong!"})
       }

       })
       .catch((error) => { reject(error.response.data) })
   })
 },
 updateCity({ commit }, payload) {
  return new Promise((resolve, reject) => {
    city.updateCity(payload)
      .then((response) => {
        if(response.status === 200) {
          commit('SET_CITY', response.data.data)
          commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
          resolve(response)
      } else {
          reject({message: "Something went wrong!"})
      }

      })
      .catch((error) => { reject(error.response.data) })
  })
},
  fetchCities({ commit }, payload) {
     return new Promise((resolve, reject) => {
       city.fetchCities(payload.meta)
         .then((response) => {
           if(response.data.data[0]) {
             commit('SET_CITIES', response.data.data)
             commit('SET_META', response.data.meta)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         }  else {
             reject({message: response.data.message})
         }

         })
         .catch((error) => { reject(error) })
     })
  },
  searchCities({ commit }, payload) {
    return new Promise((resolve, reject) => {
      city.searchCities(payload.meta)
        .then((response) => {
           if(response.data.data[0]) {
             commit('SET_CITIES', response.data.data)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updatePaginate({ commit }, size) {
    commit('UPDATE_PAGINATE', size)
  }
}
