import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store/store'

import layout from '../layout'


Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: layout,
      children: [
        {
          path: "",
          redirect: "/dashboard"
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          component: () => import('@/pages/dashboard'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: "/otps",
          name: "otps",
          component: () => import('@/pages/otp/OtpList'),
          meta: {
            rule: "admin"
          }
        }
      ]
    },
    {
      path: '/customers',
      component: layout,
      children: [
        {
          path: 'list',
          name: 'customer-list',
          component: () => import('@/pages/customers/CustomersList'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'active',
          name: 'customer-active',
          component: () => import('@/pages/customers/ActiveCustomers'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: ':id',
          name: 'customer-view',
          component: () => import('@/pages/customers/CustomerView'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: ':id/edit',
          name: 'customer-edit',
          component: () => import('@/pages/customers/CustomerView'),
          meta: {
            rule: "admin"
          }
        },
      ]
    },
    {
      path: '/admins',
      component: layout,
      children: [
        {
          path: 'list',
          name: 'admin-list',
          component: () => import('@/pages/admins/AdminsList'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'new',
          name: 'admin-add',
          component: () => import('@/pages/admins/AddNew'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: ':id',
          name: 'admin-view',
          component: () => import('@/pages/admins/AdminView'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: ':id/edit',
          name: 'admin-edit',
          component: () => import('@/pages/admins/AdminView'),
          meta: {
            rule: "admin"
          }
        },
      ]
    },
    {
      path: '/plots',
      component: layout,
      children: [
        {
          path: 'list',
          name: 'plot-list',
          component: () => import('@/pages/plots/PlotsList'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'feedbacks',
          name: 'feedbacks',
          component: () => import('@/pages/plots/Feedbacks'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'viewed',
          name: 'viewed',
          component: () => import('@/pages/plots/ViewedPlots'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'most-viewed',
          name: 'Most Viewed',
          component: () => import('@/pages/plots/MostViewedPlots'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'shared-history',
          name: 'shared-history',
          component: () => import('@/pages/plots/SharedHistory'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'new',
          name: 'plot-add',
          component: () => import('@/pages/plots/AddNew'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: ':id',
          name: 'plot-view',
          component: () => import('@/pages/plots/PlotView'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: ':id/edit',
          name: 'plot-edit',
          component: () => import('@/pages/plots/PlotEdit'),
          meta: {
            rule: "admin"
          }
        },
      ]
    },
    {
      path: '/plans',
      component: layout,
      children: [
        {
          path: 'list',
          name: 'plan-list',
          component: () => import('@/pages/plans/PlansList'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'shared-history',
          name: 'shared-plan-history',
          component: () => import('@/pages/plans/SharedHistory'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'new',
          name: 'plan-add',
          component: () => import('@/pages/plans/AddNew'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'upload-geojson',
          name: 'upload-geojson',
          component: () => import('@/pages/plans/UploadGeo'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'upload-statuses',
          name: 'upload-statuses',
          component: () => import('@/pages/plans/UploadList'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'styles',
          name: 'style-list',
          component: () => import('@/pages/plans/StyleList'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'styles/new',
          name: 'style-new',
          component: () => import('@/pages/plans/AddStyle'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'styles/:id/update',
          name: 'style-update',
          component: () => import('@/pages/plans/UpdateStyle'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'upload-statuses/process/:id',
          name: 'upload-process',
          component: () => import('@/pages/plans/UploadView'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: ':id',
          name: 'plan-view',
          component: () => import('@/pages/plans/PlanView'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: ':id/edit',
          name: 'plan-edit',
          component: () => import('@/pages/plans/PlanEdit'),
          meta: {
            rule: "admin"
          }
        },
      ]
    },
    {
      path: '/cities',
      component: layout,
      children: [
        {
          path: 'list',
          name: 'city-list',
          component: () => import('@/pages/cities/CitiesList'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'new',
          name: 'city-add',
          component: () => import('@/pages/cities/AddNew'),
          meta: {
            rule: "admin"
          }
        },
      ]
    },
    {
      path: '/favourites',
      component: layout,
      children: [
        {
          path: 'plan',
          name: 'plan-favourite',
          component: () => import('@/pages/favourites/PlanFavourites'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'plot',
          name: 'plot-favourite',
          component: () => import('@/pages/favourites/PlotFavourites'),
          meta: {
            rule: "admin"
          }
        }
      ]
    },
    {
      path: '/transactions',
      component: layout,
      children: [
        {
          path: 'new',
          name: 'transaction-add',
          component: () => import('@/pages/transactions/AddNew'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'list',
          name: 'transaction-list',
          component: () => import('@/pages/transactions/TransactionsList'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: ':id',
          name: 'transaction-view',
          component: () => import('@/pages/transactions/TransactionView'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: ':id/edit',
          name: 'transaction-edit',
          component: () => import('@/pages/transactions/TransactionEdit'),
          meta: {
            rule: "admin"
          }
        },
      ]
    },
    {
      path: '/imports',
      component: layout,
      children: [
        {
          path: '',
          name: 'import-list',
          component: () => import('@/pages/imports/ImportsList'),
          meta: {
            rule: "admin"
          }
        }
      ]
    },

    {
      path: '/widgets',
      component: layout,
      children: [
        {
          path: '',
          name: 'widgets',
          component: () => import('@/pages/widgets')
        }
      ]
    },
    {
      path: '/basic-ui',
      component: layout,
      children: [
        {
          path: 'accordions',
          name: 'accordions',
          component: () => import('@/pages/basic-ui/accordions')
        },
        {
          path: 'badges',
          name: 'badges',
          component: () => import('@/pages/basic-ui/badges'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'alerts',
          name: 'alerts',
          component: () => import('@/pages/basic-ui/alerts')
        },
        {
          path: 'breadcrumbs',
          name: 'breadcrumbs',
          component: () => import('@/pages/basic-ui/breadcrumbs')
        },
        {
          path: 'buttons',
          name: 'buttons',
          component: () => import('@/pages/basic-ui/buttons'),
          meta: {
            rule: 'public'
          }
        },
        {
          path: 'dropdowns',
          name: 'dropdowns',
          component: () => import('@/pages/basic-ui/dropdowns')
        },
        {
          path: 'modals',
          name: 'modals',
          component: () => import('@/pages/basic-ui/modals')
        },
        {
          path: 'paginations',
          name: 'paginations',
          component: () => import('@/pages/basic-ui/paginations')
        },
        {
          path: 'progress',
          name: 'progress',
          component: () => import('@/pages/basic-ui/progress')
        },
        {
          path: 'tabs',
          name: 'tabs',
          component: () => import('@/pages/basic-ui/tabs'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'tooltips',
          name: 'tooltips',
          component: () => import('@/pages/basic-ui/tooltips')
        },
        {
          path: 'typography',
          name: 'typography',
          component: () => import('@/pages/basic-ui/typography')
        }
      ]
    },
    {
      path: '/advanced-ui',
      component: layout,
      children: [
        {
          path: 'dragula',
          name: 'dragula',
          component: () => import('@/pages/advanced-ui/dragula')
        },
        {
          path: 'carousel',
          name: 'carousel',
          component: () => import('@/pages/advanced-ui/carousel')
        },
        {
          path: 'loaders',
          name: 'loaders',
          component: () => import('@/pages/advanced-ui/loaders'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'slider',
          name: 'slider',
          component: () => import('@/pages/advanced-ui/slider')
        },
        {
          path: 'context-menu',
          name: 'context-menu',
          component: () => import('@/pages/advanced-ui/context-menu')
        },
        {
          path: 'clipboard',
          name: 'clipboard',
          component: () => import('@/pages/advanced-ui/clipboard')
        },
        {
          path: 'tree-view',
          name: 'tree-view',
          component: () => import('@/pages/advanced-ui/tree-view')
        }
      ]
    },
    {
      path: '/charts',
      component: layout,
      children: [
        {
          path: 'chartjs',
          name: 'chartjs',
          component: () => import('@/pages/charts/chartjs')
        },
        {
          path: 'c3',
          name: 'c3',
          component: () => import('@/pages/charts/c3')
        },
        {
          path: 'chartist',
          name: 'chartist',
          component: () => import('@/pages/charts/chartist')
        },
        {
          path: 'googleChart',
          name: 'googleChart',
          component: () => import('@/pages/charts/googleChart')
        },
        {
          path: 'justgage',
          name: 'justgage',
          component: () => import('@/pages/charts/justgage')
        },
      ]
    },
    {
      path: '/maps',
      component: layout,
      children: [
        {
          path: 'google-map',
          name: 'google-map',
          component: () => import('@/pages/maps/google-map')
        }
      ]
    },
    {
      path: '/tables',
      component: layout,
      children: [
        {
          path: 'basic-tables',
          name: 'basic-tables',
          component: () => import('@/pages/tables/basic-tables'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'advanced-tables',
          name: 'advanced-tables',
          component: () => import('@/pages/tables/advanced-tables'),
          meta: {
            rule: "admin"
          }
        }
      ]
    },
    {
      path: '/',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/samples/auth-pages/login'),
          meta: {
            rule: "public"
          }
        },
        {
          path: 'forgot-password',
          name: 'forgot-password',
          component: () => import('@/pages/samples/auth-pages/forgot-password'),
          meta: {
            rule: "public"
          }
        },
        {
          path: 'reset-password',
          name: 'reset-password',
          component: () => import('@/pages/samples/auth-pages/reset-password'),
          meta: {
            rule: "public"
          }
        },
        {
          path: 'login-2',
          name: 'login-2',
          component: () => import('@/pages/samples/auth-pages/login-2'),
          meta: {
            rule: "public"
          }
        },
        {
          path: 'multi-level-login',
          name: 'multi-level-login',
          component: () => import('@/pages/samples/auth-pages/multi-level-login')
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('@/pages/samples/auth-pages/register')
        },
        {
          path: 'register-2',
          name: 'register-2',
          component: () => import('@/pages/samples/auth-pages/register-2')
        },
        {
          path: 'lock-screen',
          name: 'lock-screen',
          component: () => import('@/pages/samples/auth-pages/lock-screen')
        },
      ]
    },
    {
      path: '/error-pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          name: 'error-404',
          component: () => import('@/pages/samples/error-pages/error-404')
        },
        {
          path: 'error-500',
          name: 'error-500',
          component: () => import('@/pages/samples/error-pages/error-500')
        }
      ]
    },
    {
      path: '/general-pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'landing-page',
          name: 'landing-page',
          component: () => import('@/pages/samples/general-pages/landing-page')
        }
      ]
    },
    {
      path: '/general-pages',
      component: layout,
      children: [
        {
          path: 'blank-page',
          name: 'blank-page',
          component: () => import('@/pages/samples/general-pages/blank-page')
        },
        {
          path: 'profile',
          name: 'profile',
          component: () => import('@/pages/samples/general-pages/profile'),
          meta: {
            rule: 'admin'
          }
        },
        {
          path: 'faq-1',
          name: 'faq-1',
          component: () => import('@/pages/samples/general-pages/faq-1')
        },
        {
          path: 'faq-2',
          name: 'faq-2',
          component: () => import('@/pages/samples/general-pages/faq-2')
        },
        {
          path: 'news-grid',
          name: 'news-grid',
          component: () => import('@/pages/samples/general-pages/news-grid')
        },
        {
          path: 'timeline',
          name: 'timeline',
          component: () => import('@/pages/samples/general-pages/timeline')
        },
        {
          path: 'search-result',
          name: 'search-result',
          component: () => import('@/pages/samples/general-pages/search-result'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'portfolio',
          name: 'portfolio',
          component: () => import('@/pages/samples/general-pages/portfolio')
        },
        {
          path: 'user-listing',
          name: 'user-listing',
          component: () => import('@/pages/samples/general-pages/user-listing')
        },
      ]
    },
    {
      path: '/notifications',
      component: layout,
      children: [
        {
          path: '',
          name: 'notifications',
          component: () => import('@/pages/advanced-ui/notifications'),
          meta: {
            rule: 'public'
          }
        }
      ]
    },
    {
      path: '/popups',
      component: layout,
      children: [
        {
          path: '',
          name: 'popups',
          component: () => import('@/pages/advanced-ui/popups')
        }
      ]
    },
    {
      path: '/forms',
      component: layout,
      children: [
        {
          path: 'basic-elements',
          name: 'basic-elements',
          component: () => import('@/pages/forms/basic-form-elements'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'advanced-elements',
          name: 'advanced-elements',
          component: () => import('@/pages/forms/advanced-form-elements'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'wizard',
          name: 'wizard',
          component: () => import('@/pages/forms/wizard')
        },
        {
          path: 'validation',
          name: 'validation',
          component: () => import('@/pages/forms/validation'),
          meta: {
            rule: "admin"
          }
        }
      ]
    },
    {
      path: '/text-editors',
      component: layout,
      children: [
        {
          path: '',
          name: 'text-editors',
          component: () => import('@/pages/forms/text-editors')
        }
      ]
    },
    {
      path: '/code-editors',
      component: layout,
      children: [
        {
          path: '',
          name: 'code-editors',
          component: () => import('@/pages/forms/code-editors')
        }
      ]
    },
    {
      path: '/icons',
      component: layout,
      children: [
        {
          path: 'flag-icons',
          name: 'flag-icons',
          component: () => import('@/pages/icons/flag-icons')
        },
        {
          path: 'fontawesome',
          name: 'fontawesome',
          component: () => import('@/pages/icons/fontawesome')
        },
        {
          path: 'mdi-icons',
          name: 'mdi-icons',
          component: () => import('@/pages/icons/mdi-icons'),
          meta: {
            rule: "admin"
          }
        },
        {
          path: 'simpleline',
          name: 'simpleline',
          component: () => import('@/pages/icons/simple-line')
        },
        {
          path: 'themify-icons',
          name: 'themify-icons',
          component: () => import('@/pages/icons/themify')
        }
      ]
    },
    {
      path: '/e-commerce',
      component: layout,
      children: [
        {
          path: 'invoice',
          name: 'invoice',
          component: () => import('@/pages/samples/e-commerce/invoice')
        },
        {
          path: 'orders',
          name: 'orders',
          component: () => import('@/pages/samples/e-commerce/orders')
        },
        {
          path: 'pricing-table',
          name: 'pricing-table',
          component: () => import('@/pages/samples/e-commerce/pricing-table')
        },
        {
          path: 'product-catalogue',
          name: 'product-catalogue',
          component: () => import('@/pages/samples/e-commerce/product-catalogue')
        },
        {
          path: 'project-list',
          name: 'project-list',
          component: () => import('@/pages/samples/e-commerce/project-list')
        }
      ]
    },
    {
      path: '/apps',
      component: layout,
      children: [
        {
          path: 'calendar',
          name: 'calendar',
          component: () => import('@/pages/apps/calendar')
        },
        {
          path: 'email',
          name: 'email',
          component: () => import('@/pages/apps/email')
        },
        {
          path: 'todo',
          name: 'todo',
          component: () => import('@/pages/apps/todo')
        },
        {
          path: 'gallery',
          name: 'gallery',
          component: () => import('@/pages/apps/gallery')
        },
        {
          path: 'tickets',
          name: 'tickets',
          component: () => import('@/pages/apps/tickets')
        },
        {
          path: 'kanban',
          name: 'kanban',
          component: () => import('@/pages/apps/kanban')
        },
        {
          path: 'chat',
          name: 'chat',
          component: () => import('@/pages/apps/chat')
        },
      ]
    },
    {
      path: '*',
      redirect: '/error-404',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          component: () => import('@/pages/samples/error-pages/error-404')
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if(to.meta.rule != 'public') {
    if (!store.state.auth.isUserLoggedIn()) next('/login')
  }
   next()
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
