export default {
  cities: [],
  meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  }
}
