import state from './modulePlotState.js'
import mutations from './modulePlotMutations.js'
import actions from './modulePlotActions.js'
import getters from './modulePlotGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
