export default {
  SET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions
  },
  SET_META(state, meta) {
   state.meta.total = meta.total
   state.meta.to = meta.to
   state.meta.from = meta.from
   state.meta.last_page = meta.last_page
   state.meta.current_page = meta.current_page
   if(meta.current_page != meta.last_page) {
     state.meta.next_page = meta.current_page + 1
   }
  },
  SET_TRANSACTION(state, transaction) {
    if(state.transactions.length) {
      const itemIndex = state.transactions.findIndex((t) => t.id === transaction.id)
      if(itemIndex != -1) {
        state.transactions[itemIndex] = transaction
      }
      else state.transactions.push(transaction)
    }
    else state.transactions.push(transaction)
  },
  UPDATE_PAGINATE(state, size) {
    state.meta.paginate = size
  },
}
