import axios from "../../axios/index.js"

export default {
  createAdmin(payload) {
    return axios.post("register", payload)
  },
  fetchAdmins(meta) {
    return axios.get("admin-users", {
      params: meta
    })
  },
  fetchAdmin(id) {
    return axios.get("admin-users/" + id)
  },
  deleteAdmin(id) {
    return axios.delete("admin-users/" + id)
  },
  updateAdmin(payload) {
    return axios.post(
      "admin-users/" + payload.id, 
      payload
    )
  },
  fetchOtps(meta) {
    return axios.get("otps", {
      params: meta
    })
  },
  fetchStats() {
    return axios.get("metrics/stat")
  },
  getAnalyticsBrowser() {
    return axios.get('metrics/top-browser')
  },
  getAnalyticsSession() {
    return axios.get(`metrics/sessions`)
  },
  getAnalyticsPageViews() {
    return axios.get(`metrics/page_views`)
  },
  getAnalyticsUsers() {
    return axios.get(`metrics/users`)
  },
}
