export default {
  SET_PLOTS(state, plots) {
    state.plots = plots
  },
  SET_SUB_CLASSIFICATIONS(state, sub_classifications) {
    state.sub_classifications = sub_classifications
  },
  SET_FEEDBACKS(state, feedbacks) {
    state.feedbacks = feedbacks
  },
  SET_SHARED(state, shared) {
    state.shared = shared
  },
  SET_VIEWED(state, viewed) {
    state.viewed = viewed
  },
  SET_MOST_VIEWED(state, viewed) {
    state.most_viewed = viewed
  },
  SET_META(state, meta) {
   state.meta.total = meta.total
   state.meta.to = meta.to
   state.meta.from = meta.from
   state.meta.last_page = meta.last_page
   state.meta.current_page = meta.current_page
   if(meta.current_page != meta.last_page) {
     state.meta.next_page = meta.current_page + 1
   }
  },
  SET_FEEDBACKS_META(state, meta) {
    state.feedbacks_meta.total = meta.total
    state.feedbacks_meta.to = meta.to
    state.feedbacks_meta.from = meta.from
    state.feedbacks_meta.last_page = meta.last_page
    state.feedbacks_meta.current_page = meta.current_page
    if(meta.current_page != meta.last_page) {
      state.feedbacks_meta.next_page = meta.current_page + 1
    }
   },
   SET_SHARED_META(state, meta) {
    state.shared_meta.total = meta.total
    state.shared_meta.to = meta.to
    state.shared_meta.from = meta.from
    state.shared_meta.last_page = meta.last_page
    state.shared_meta.current_page = meta.current_page
    if(meta.current_page != meta.last_page) {
      state.shared_meta.next_page = meta.current_page + 1
    }
   },
   SET_VIEWED_META(state, meta) {
    state.viewed_meta.total = meta.total
    state.viewed_meta.to = meta.to
    state.viewed_meta.from = meta.from
    state.viewed_meta.last_page = meta.last_page
    state.viewed_meta.current_page = meta.current_page
    if(meta.current_page != meta.last_page) {
      state.viewed_meta.next_page = meta.current_page + 1
    }
   },
   SET_MOST_VIEWED_META(state, meta) {
    state.most_viewed_meta.total = meta.total
    state.most_viewed_meta.to = meta.to
    state.most_viewed_meta.from = meta.from
    state.most_viewed_meta.last_page = meta.last_page
    state.most_viewed_meta.current_page = meta.current_page
    if(meta.current_page != meta.last_page) {
      state.most_viewed_meta.next_page = meta.current_page + 1
    }
   },
  SET_SUB_CLASSIFICATION_META(state, meta) {
   state.sub_classification_meta.total = meta.total
   state.sub_classification_meta.to = meta.to
   state.sub_classification_meta.from = meta.from
   state.sub_classification_meta.last_page = meta.last_page
   state.sub_classification_meta.current_page = meta.current_page
   if(meta.current_page != meta.last_page) {
     state.sub_classification_meta.next_page = meta.current_page + 1
   }
  },
  SET_PLOT(state, plot) {
    if(state.plots.length) {
      const itemIndex = state.plots.findIndex((p) => p.id === plot.id)
      if(itemIndex != -1) {
        state.plots[itemIndex] = plot
      }
      else state.plots.push(plot)
    }
    else state.plots.push(plot)
  },
  UPDATE_PAGINATE(state, size) {
    state.meta.paginate = size
  },
}
