export default {
  SET_CITIES(state, cities) {
    state.cities = cities
  },
  SET_CITY(state, city) {
    if(state.cities.length) {
      const itemIndex = state.cities.findIndex((c) => c.id === city.id)
      if(itemIndex != -1) {
        state.cities[itemIndex] = city
      }
      else state.cities.push(city)
    }
    else state.cities.push(city)
  },
  SET_META(state, meta) {
   state.meta.total = meta.total
   state.meta.to = meta.to
   state.meta.from = meta.from
   state.meta.last_page = meta.last_page
   state.meta.current_page = meta.current_page
   if(meta.current_page != meta.last_page) {
     state.meta.next_page = meta.current_page + 1
   }
  },
  UPDATE_PAGINATE(state, size) {
    state.meta.paginate = size
  },
}
