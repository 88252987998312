import plan from "../../http/requests/plan/index.js"
/* eslint-disable no-debugger, no-console */

export default {
  importPlan({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.importPlan(payload)
        .then((response) => {
          if (response.status === 200) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }

        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadGeoJSON({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.uploadGeoJSON(payload)
        .then((response) => {
          if (response.status === 200) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }

        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateGeoJSON({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.updateGeoJSON(payload)
        .then((response) => {
          if (response.status === 200) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }

        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createPlan({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.createPlan(payload)
        .then((response) => {
          if (response.status === 201) {
            commit('SET_PLAN', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: "Something went wrong!"})
          }

        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },
  updatePlan({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.updatePlan(payload)
        .then((response) => {
          if (response.status === 200) {
            commit('SET_PLAN', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: "Something went wrong!"})
          }

        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },
  addStyle({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.addStyle(payload)
        .then((response) => {
          if (response.status === 201) {
            commit('SET_STYLE', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: "Something went wrong!"})
          }

        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },
  updateStyle({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.updateStyle(payload)
        .then((response) => {
          if (response.status === 201) {
            commit('SET_STYLE', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: "Something went wrong!"})
          }

        })
        .catch((error) => {
          reject(error.response.data)
        })
    })
  },
  reprocess({commit}, id) {
    return new Promise((resolve, reject) => {
      plan.reprocess(id)
        .then((response) => {
          if (response.status === 200) {
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: "Something went wrong!"})
          }

        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchPlans({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchPlans(payload.meta)
        .then((response) => {
          if (response.data.data[0]) {
            commit('SET_PLANS', response.data.data)
            commit('SET_META', response.data.meta)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }

        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchStyles({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchStyles(payload.meta)
        .then((response) => {
          if (response.data.data[0]) {
            commit('SET_STYLES', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }

        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchStyle({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchStyle(payload.meta)
        .then((response) => {
          if (response.data.data[0]) {
            //commit('SET_STYLES', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }

        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchUploadStatuses({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchUploadStatuses(payload.meta)
        .then((response) => {
          if (response.data.data[0]) {
            commit('SET_UPLOAD_STATUSES', response.data.data)
            commit('SET_STATUS_META', response.data.meta)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }

        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchPlanFavourites({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchPlanFavourites(payload.meta)
        .then((response) => {
          if (response.data.data[0]) {
            commit('SET_PLAN_FAVOURITES', response.data.data)
            commit('SET_PLAN_FAVOURITES_META', response.data.meta)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }

        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  emptyFavouritePlots({commit}) {
    commit('SET_PLOT_FAVOURITES', []) // dev push
  },
  fetchPlotFavourites({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.fetchPlotFavourites(payload.meta)
        .then((response) => {
          if (response.data.data[0]) {
            commit('SET_PLOT_FAVOURITES', response.data.data)
            commit('SET_PLOT_FAVOURITES_META', response.data.meta)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }

        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchPlans({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.searchPlans(payload.meta)
        .then((response) => {
          if (response.data.data[0]) {
            commit('SET_PLANS', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchPlan({commit}, id) {
    return new Promise((resolve, reject) => {
      plan.fetchPlan(id)
        .then((response) => {
          if (response.data.data) {
            commit('SET_PLAN', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchUploadProcess({commit}, meta) {
    return new Promise((resolve, reject) => {
      plan.fetchUploadProcess(meta)
        .then((response) => {
          if (response.data.data) {
            commit('SET_UPLOAD_PROCESS', response.data.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sharedHistory({commit}, payload) {
    return new Promise((resolve, reject) => {
      plan.sharedHistory(payload.meta)
        .then((response) => {
          if (response.data.data[0]) {
            commit('SET_SHARED', response.data.data)
            commit('SET_SHARED_META', response.data.meta)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }

        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updatePaginate({commit}, size) {
    commit('UPDATE_PAGINATE', size)
  }
}
