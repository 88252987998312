/* eslint-disable no-debugger, no-console */
import plot from "../../http/requests/plot/index.js"

export default {
  fetchPlots({ commit }, payload) {
     return new Promise((resolve, reject) => {
       plot.fetchPlots(payload.meta)
         .then((response) => {
           if(response.data.data[0]) {
             commit('SET_PLOTS', response.data.data)
             commit('SET_META', response.data.meta)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         }  else {
             reject({message: response.data.message})
         }

         })
         .catch((error) => { reject(error) })
     })
  },
  fetchPlot({ commit }, id) {
    return new Promise((resolve, reject) => {
      plot.fetchPlot(id)
        .then((response) => {
           if(response.data.data) {
             commit('SET_PLOT', response.data.data)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  searchPlots({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plot.searchPlots(payload.meta)
        .then((response) => {
           if(response.data.data[0]) {
             commit('SET_PLOTS', response.data.data)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchSubClassifications({ commit }, payload) {
     return new Promise((resolve, reject) => {
       plot.fetchSubClassifications(payload.meta)
         .then((response) => {
           if(response.data.data[0]) {
             commit('SET_SUB_CLASSIFICATIONS', response.data.data)
             commit('SET_SUB_CLASSIFICATION_META', response.data.meta)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         }  else {
             reject({message: response.data.message})
         }

         })
         .catch((error) => { reject(error) })
     })
  },
  fetchFeedbacks({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plot.fetchFeedbacks(payload.meta)
        .then((response) => {
          if(response.data.data[0]) {
            commit('SET_FEEDBACKS', response.data.data)
            commit('SET_FEEDBACKS_META', response.data.meta)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
        }  else {
            reject({message: response.data.message})
        }
  
        })
        .catch((error) => { 
          reject(error) 
        })
    })
  },
  sharedHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plot.sharedHistory(payload.meta)
        .then((response) => {
          if(response.data.data[0]) {
            commit('SET_SHARED', response.data.data)
            commit('SET_SHARED_META', response.data.meta)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
        }  else {
            reject({message: response.data.message})
        }
  
        })
        .catch((error) => { 
          reject(error) 
        })
    })
  },
  viewedPlots({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plot.viewedPlots(payload.meta)
        .then((response) => {
          if(response.data.data) {
            commit('SET_VIEWED', response.data.data)
            commit('SET_VIEWED_META', response.data.meta)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
        }  else {
            reject({message: response.data.message})
        }
  
        })
        .catch((error) => { 
          reject(error) 
        })
    })
  },
  mostViewedPlots({ commit }, payload) {
    return new Promise((resolve, reject) => {
      plot.mostViewedPlots(payload.meta)
        .then((response) => {
          if(response.data.data) {
            commit('SET_MOST_VIEWED', response.data.data)
            commit('SET_MOST_VIEWED_META', response.data.meta)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
        }  else {
            reject({message: response.data.message})
        }
  
        })
        .catch((error) => {
          commit('SET_MOST_VIEWED', [])
          reject(error) 
        })
    })
  },
  updatePaginate({ commit }, size) {
    commit('UPDATE_PAGINATE', size)
  }
}
