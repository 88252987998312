import axios from "../../axios/index.js"

export default {
  fetchCustomers(meta) {
    return axios.get("customers", {
      params: meta
    })
  },
  fetchExelCustomers() {
    return axios.get("customers")
  },
  fetchActiveCustomers(meta) {
    return axios.get("metrics/active-users", {
      params: meta
    })
  },
  searchCustomers(meta) {
    return axios.get("customers/search", {
      params:  meta
    })
  },
  searchCustomerList(search,start_date,end_date,paginate=20, page = undefined) {
    let queryParam;

    if(search === ""){
      queryParam = `start_date=${start_date}&end_date=${end_date}&paginate=${paginate}`
    }else if(start_date === null && end_date === null){
      queryParam = `term=${search}&paginate=${paginate}`
    }else {
      queryParam = `term=${search}&start_date=${start_date}&end_date=${end_date}&paginate=${paginate}`
    }
    return axios.get(`customers?${page ? `page=${page}&` : ''}${queryParam}`)
  },
  fetchCustomer(id) {
    return axios.get(`customers/findById/${id}`)
  },
  deleteCustomer(id) {
    return axios.delete(`customers/delete/${id}`)
  },
  updateCustomer(payload) {
    return axios.post(`customers/update/${payload.id}`, payload)
  }
}
