import importation from "../../http/requests/import/index.js"

export default {
  importHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      importation.importHistory(payload.meta)
        .then((response) => {
           if(response.data.data[0]) {
             commit('SET_HISTORY', response.data.data)
             commit('SET_META', response.data.meta)
             commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
             resolve(response)
         } else {
            reject({message: response.data.message})
         }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updatePaginate({ commit }, size) {
    commit('UPDATE_PAGINATE', size)
  }
}
