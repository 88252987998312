export default {
  plans: [],
  favourite_plans: [],
  favourite_plots: [],
  styles: [],
  feedbacks: [],
  shared: [],
  upload_statuses: [],
  upload_process: {},
  meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
  favourite_plan_meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
  shared_meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
  favourite_plot_meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
  status_meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
}
