import axios from "../../axios/index.js"

export default {
  importPlan(payload) {
    return axios.post(`import-plans-sheet`, payload)
  },
  uploadGeoJSON(payload) {
    return axios.post(`upload-geojson`, payload)
  },
  updateGeoJSON(payload) {
    return axios.post(`plans/${payload.id}/geojson`, payload.form_data)
  },
  reprocess(id) {
    return axios.patch(`plans/${id}/reprocess`)
  },
  fetchPlans(meta) {
    return axios.get("plans", {
      params: meta
    })
  },
  fetchStyles(meta) {
    return axios.get("style/listing", {
      params: meta
    })
  },
  fetchStyle(meta) {
    return axios.get("style/listing", {
      params: meta
    })
  },
  fetchUploadStatuses(meta) {
    return axios.get("mapbox-upload-status", {
      params: meta
    })
  },
  fetchUploadProcess(meta) {
    return axios.get("mapbox-process-list", {
      params: meta
    })
  },
  fetchPlanFavourites(meta) {
    return axios.get("metrics/favourite-plans", {
      params: meta
    })
  },
  fetchPlotFavourites(meta) {
    return axios.get("metrics/favourite-plots", {
      params: meta
    })
  },
  searchPlans(meta) {
    return axios.get("plans/search", {
      params:  meta
    })
  },
  fetchPlan(id) {
    return axios.get(`plans/${id}`)
  },
  createPlan(payload) {
    return axios.post("plans", payload)
  },
  updatePlan(payload) {
      return axios.post(`plans/${payload.id}`, payload.plan)
  },
  addStyle(payload) {
    return axios.post("style/new", payload)
  },
  updateStyle(payload) {
    return axios.post(`style/update/${payload.id}`, payload.style)
  },
  sharedHistory(meta) {
    return axios.get("metrics/shared-plans-listing", {
      params: meta
    })
  },
}
