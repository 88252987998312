import axios from "../../axios/index.js"

export default {
  fetchPlots(meta) {
    return axios.get("plans/plots", {
      params: meta
    })
  },
  searchPlots(meta) {
    return axios.get("plans/plots/search", {
      params:  meta
    })
  },
  fetchPlot(id) {
    return axios.get(`plans/plots/${id}`)
  },
  fetchSubClassifications(meta) {
    return axios.get("plans/sub-classifications", {
      params: meta
    })
  },
  fetchFeedbacks(meta) {
    return axios.get("metrics/feedbacks", {
      params: meta
    })
  },
  sharedHistory(meta) {
    return axios.get("metrics/shared-plots-listing", {
      params: meta
    })
  },
  viewedPlots(meta) {
    return axios.get("metrics/viewed-plots", {
      params: meta
    })
  },
  mostViewedPlots(meta) {
    return axios.get("metrics/most-viewed", {
      params: meta
    })
  },
}
