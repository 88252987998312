export default {
  admins: [],
  browsers: {},
  session: {},
  pageViews: {},
  analyticsUsers: {},
  otps: [],
  stats: {
    total_viewed_plots: 0,
    total_shared_plots: 0,
    total_shared_plans: 0,
    total_fav_plots: 0,
    total_fav_plans: 0,
    total_users: 0,
    total_feedbacks: 0,
},
  meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
  otp_meta: {
    current_page: 1,
    next_page: 1,
    paginate: 10,
    from: 0,
    to: 0,
    total: 0,
    last_page: 1
  },
}
