import admin from "../../http/requests/admin/index.js"

export default {
  createAdmin({ commit }, payload) {
   return new Promise((resolve, reject) => {
     admin.createAdmin(payload)
       .then((response) => {
         if(response.status === 201) {
           commit('ADD_ADMIN', response.data.data)
           commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
           resolve(response)
       } else {
           reject({message: "Something went wrong!"})
       }

       })
       .catch((error) => { reject(error.response.data) })
   })
 },
 fetchAdmins({ commit }, payload) {
   return new Promise((resolve, reject) => {
     admin.fetchAdmins(payload.meta)
       .then((response) => {
          if(response.data.data[0]) {
            commit('SET_ADMINS', response.data.data)
            commit('SET_META', response.data.meta)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
        } else {
           reject({message: response.data.message})
        }
       })
       .catch((error) => {
         reject(error)
       })
   })
 },
 fetchAdmin({ commit }, payload) {
  return new Promise((resolve, reject) => {
    admin.fetchAdmin(payload)
      .then((response) => {
         if(response.data.data) {
           commit('SET_ADMIN_PROFILE', response.data.data)
           commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
           resolve(response)
       } else {
          reject({message: response.data.message})
       }
      })
      .catch((error) => {
        reject(error)
      })
  })
},
updateAdmin({ commit }, payload) {
  return new Promise((resolve, reject) => {
    admin.updateAdmin(payload)
      .then((response) => {
         if(response.data.data) {
           commit('UPDATE_ADMIN', response.data.data)
           commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
           resolve(response)
       } else {
          reject({message: response.data.message})
       }
      })
      .catch((error) => {
        reject(error)
      })
  })
},
deleteAdmin({ commit }, payload) {
  return new Promise((resolve, reject) => {
    admin.deleteAdmin(payload.id)
      .then((response) => {
        commit('REMOVE_ADMIN', payload)
        commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
},
 fetchOtps({ commit }, payload) {
  return new Promise((resolve, reject) => {
    admin.fetchOtps(payload.meta)
      .then((response) => {
         if(response.data.data[0]) {
           commit('SET_OTPS', response.data.data)
           commit('SET_OTPS_META', response.data.meta)
           commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
           resolve(response)
       } else {
          reject({message: response.data.message})
       }
      })
      .catch((error) => {
        reject(error)
      })
  })
},
fetchStats({ commit }) {
  return new Promise((resolve, reject) => {
    admin.fetchStats()
      .then((response) => {
         if(response.status == 200) {
           commit('SET_STATS', response.data)
           commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
           resolve(response)
       } else {
          reject({message: response.data.message})
       }
      })
      .catch((error) => {
        reject(error)
      })
  })
},
  fetchAnalyticsTopBrowsers({commit}) {
    return new Promise((resolve, reject) => {
      admin.getAnalyticsBrowser()
        .then((response) => {
          if(response.status == 200) {
            commit('SET_ANALYTICS_BROWSER', response.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchAnalyticsTopSession({commit}) {
    return new Promise((resolve, reject) => {
      admin.getAnalyticsSession()
        .then((response) => {
          if(response.status == 200) {
            commit('SET_ANALYTICS_SESSION', response.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchAnalyticsUsers({commit}) {
    return new Promise((resolve, reject) => {
      admin.getAnalyticsUsers()
        .then((response) => {
          if(response.status == 200) {
            commit('SET_ANALYTICS_USERS', response.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchAnalyticsTopPageViews({commit}) {
    return new Promise((resolve, reject) => {
      admin.getAnalyticsPageViews()
        .then((response) => {
          if(response.status == 200) {
            commit('SET_ANALYTICS_PAGE_VIEWS', response.data)
            commit('UPDATE_TIMESTAMP', new Date().getTime(), {root: true})
            resolve(response)
          } else {
            reject({message: response.data.message})
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updatePaginate({ commit }, size) {
    commit('UPDATE_PAGINATE', size)
  }
}
