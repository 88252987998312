import state from './moduleImportState.js'
import mutations from './moduleImportMutations.js'
import actions from './moduleImportActions.js'
import getters from './moduleImportGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
